<template>
    <div class="main-container">
      <h5 class="pageTitle">관리자 조회</h5>
      <mdb-container class="search-container">
        <mdb-row class="search-row">
          <mdb-col class="search-col">
            <!-- 검색 기준 선택 박스 -->
            <select v-model="searchCriteria" @change="updatePlaceholder" class="search-select">
              <option value="관리자명">관리자명</option>
              <option value="관리자 구분">관리자 구분</option>
              <option value="SMS 수신여부">SMS 수신여부</option>
              <option value="전화번호">전화번호</option>
            </select>
            <input
              type="text"
              v-model="search"
              :placeholder="placeholder"
              @input="filterResults"
              class="search-input"
              
            />
            <button class="btn-search" @click="searchButton">검색</button>
            <button class="btn-register" @click="goToRegister">등록</button>
          </mdb-col>
        </mdb-row>
      </mdb-container>
  
      <!-- 결과 테이블 -->
      <table>
        <thead>
          <tr>
            <th>NO.</th>
            <th>관리자 이름</th>
            <th>관리자 아이디</th>
            <th>관리자 구분</th>
            <!-- <th>퇴직 여부</th> -->
            <th>자격번호</th>
            <th>전화번호</th>
            <th>SMS 수신 여부</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="paginatedData.length === 0">
            <td colspan="8">데이터가 없습니다.</td>
          </tr>
          <tr
            v-for="(admin, index) in paginatedData"
            :key="index"
            :class="{ 'alt-row': index % 2 === 1 }"
            @click="goToEdit(admin)"
          >
            <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
            <td>{{ admin.name }}</td>
            <td>{{ admin.id }}</td>
            <td>{{ translateAdminType(admin.admin_type) }}</td>
            <!-- <td>{{ admin.retired_YN ?'예':'아니오' }}</td> -->
            <td>{{ admin.instructorNo || '-' }}</td>
            <td>{{ formatMobileNo(admin.mobile_no) }}</td>
            <td>{{ admin.sms_YN ?'예':'아니오'}}</td>
          </tr>
        </tbody>
      </table>
  
      <!-- 페이지네이션 -->
      <div class="pagination">
        <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
        <span>Page {{ currentPage }} of {{ pageCount }}</span>
        <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import { mdbCol, mdbContainer, mdbRow } from "mdbvue";
  import axios from "@/axios";
  import Swal from "sweetalert2";
  import {sendWorkLog} from "@/lib/logModule";
  
  export default {
    components: {
      mdbContainer,
      mdbRow,
      mdbCol,
    },
    data() {
      return {
        searchCriteria: "관리자명", // 기본 검색 기준
        placeholder: "검색할 관리자명을 입력하세요.",
        search: "",
        results: [], // 서버에서 가져온 원본 데이터
        filteredResults: [], // 검색된 결과 데이터
        currentPage: 1,
        perPage: 10, // 페이지 당 데이터 수
      };
    },
    computed: {
      paginatedData() {
        const start = (this.currentPage - 1) * this.perPage;
        return this.filteredResults.slice(start, start + this.perPage);
      },
      pageCount() {
        return Math.ceil(this.filteredResults.length / this.perPage);
      },
    },
    methods: {
      goToEdit(admin) {
        if(admin.id === "admin")
        {
          Swal.fire("알림", "마스터 유저의 정보는 수정 및 삭제가 불가합니다!!.", "error");
          return;
        }
        this.$router.push({
          name: "admin-updateDelete",
          params: { adminData: admin }, // 전체 데이터 전달
        });
      },
      formatMobileNo(mobileNo) {
        if (!mobileNo) return ""; // 전화번호가 없는 경우 빈 문자열 반환

        // 길이에 따라 포맷팅
        if (mobileNo.length === 10) {
          // 10자리일 경우 (3-3-4)
          return mobileNo.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        } else if (mobileNo.length === 11) {
          // 11자리일 경우 (3-4-4)
          return mobileNo.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else {
          // 예상하지 못한 포맷의 경우 그대로 반환
          return mobileNo;
        }
      },
      translateAdminType(type) {
        const typeMapping = {
          OPERATOR: "운영자",
          INSTRUCTOR: "교관",
          DIRECTOR: "원장",
        };
        return typeMapping[type] || type;
      },
      async fetchResults() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        const config = {
          method: "get",
          url: "/admins/read",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const response = await axios(config);
          const allResults = response.data; // 모든 데이터를 저장

           // retired_YN이 false인 데이터만 필터링
          this.results = allResults.filter(admin => admin.retired_YN === false);
          this.filteredResults = this.results; // 초기 필터링 데이터 설정
          sendWorkLog('100100');
        } catch (error) {
          //console.error("관리자 조회 실패:", error);
          Swal.fire("오류", "관리자 데이터를 불러오는 데 실패했습니다.", "error");
        }
      
      },

      
      updatePlaceholder() {
        const placeholders = {
          관리자명: "검색할 관리자명을 입력하세요.",
          "관리자 구분": "검색할 관리자 구분을 입력하세요.",
          "SMS 수신여부": "검색할 SMS 수신여부를 입력하세요.",
          전화번호: "검색할 전화번호를 입력하세요.",
        };
        this.placeholder = placeholders[this.searchCriteria];
      },
      filterResults() {
        const criteriaMapping = {
          관리자명: "name",
          "관리자 구분": "admin_type",
          "SMS 수신여부": "sms_YN",
          전화번호: "mobile_no",
        };
        const adminTypeMapping = {
          운영자: "operator",
          원장: "director",
          교관: "instructor",
        };

        const retiredMapping = {
          예: true,
          아니오: false,
          true: true,
          false: false,
        };
  
        const criteria = criteriaMapping[this.searchCriteria];
        const search = this.search.trim().toLowerCase();
  
        if (!search) {
          this.filteredResults = this.results;
          return;
        }
  
        this.filteredResults = this.results.filter((result) => {
        const fieldValue = result[criteria]; // 검색 기준 필드 값

        //관리자 구분: 한글 → 영어 매핑
        if (criteria === "admin_type") {
          
          const mappedValue = adminTypeMapping[this.search];          
          const fieldValue = result[criteria] || ""; // 필드 값이 없으면 빈 문자열 
          //return fieldValue === mappedValue;

          // 값이 undefined인 경우에도 안전하게 비교
          return fieldValue.toString().toLowerCase().includes(mappedValue);
        }

        // 퇴직 여부: 예/아니오 → true/false 매핑
        if (criteria === "sms_YN") {
          
          const mappedValue = retiredMapping[this.search]; // 입력값을 true/false로 매핑
          const fieldValue = result[criteria]; // 결과 데이터에서 retired_YN 값 가져오기
          
          // 매핑된 값과 필드 값을 직접 비교
          return fieldValue === mappedValue;
        }
        // 필드 값이 null, undefined인 경우 빈 문자열로 처리
        return (fieldValue ? fieldValue.toString().toLowerCase() : "").includes(search);
      });

        clearTimeout(this.searchDebounce);
        this.searchDebounce = setTimeout(() => {
          if (this.search && this.search.length > 1) {
            sendWorkLog('100200');  // 관리자 검색 
            console.log('검색 로그 전송됨:', this.search);
          }
        }, 1000);

      },
      searchButton() {
        this.filterResults();
      },
      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.pageCount) this.currentPage++;
      },
      goToRegister() {
        this.$router.push({ name: "admin-create" });
      },
    },
    mounted() {
      this.fetchResults();
    },
  };
  </script>
  
  <style scoped>
  /* 기존 스타일 유지 + 간격 수정 */
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* 검색 필터와 버튼 간격 추가 */
  .search-container {
    margin-bottom: 25px;
  }
  
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    padding: 8px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    color: #3e3e3e;
    margin-right: 20px;
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    color: #3e3e3e;
    margin-left: auto;
  }  
  
  
  .btn-search:hover {
    background-color: #d4e8ff;
  }
  
  
  .btn-register:hover {
    background-color: #e0a800;
  }
  
  /* 테이블 스타일 */
  table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  /* 페이지네이션 스타일 */
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
  .pagination-button:hover {
    background-color: #45a049;
  }
  
  .pagination-button:disabled {
    background-color: #ddd;
    color: #666;
    cursor: not-allowed;
  }
  </style>
  
